<template> <div></div></template>
<script>
export default {
  name: 'VideoEditor',
  mounted() {
    window.location.href =
      'https://video.socialagency360.com' +
      '?filekey=' +
      'a2d&from=' +
      window.location.href.replace('video-composer-editor', 'video-composer-list');
  },
};
</script>
